import React, { useEffect, useRef, useCallback, useState } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import {  selectRandomPreset } from './presets';
import './CRTBeamEffect.module.css';

// Font loading utility
const loadFonts = async (fontFamilies) => {
  const loadPromises = fontFamilies.map(family => {
    const font = new FontFaceObserver(family, { weight: 'bold' });
    return font.load(null, 5000)
      .then(() => ({ family, loaded: true }))
      .catch(() => ({ family, loaded: false }));
  });
  return Promise.all(loadPromises);
};

const CRTBeamEffect = () => {
  // State management with a single state object
  const [state, setState] = useState({
    fontsLoading: true,
    loadedFonts: new Set(),
    selectedPreset: null,
    error: null
  });

  // All refs in one object
  const canvasRef = useRef(null);
  const textCanvasRef = useRef(null);
  const beamCanvasRef = useRef(null);
  const textBoundsRef = useRef(null);
  const frameRef = useRef(null);
  const lastFrameTimeRef = useRef(0);
  const resizeTimeoutRef = useRef(null);
  const animStateRef = useRef({ scanLine: 0, beamX: 0 });

  const getScaledParams = useCallback((width, height, preset) => {
    const baseSize = Math.min(width, height);
    const dpr = window.devicePixelRatio || 1;
    const canvasWidth = width / dpr;
    const canvasHeight = height / dpr;
    const isWide = canvasWidth > canvasHeight;

    // Calculate base font size based on orientation
    let baseFontSize;
    if (isWide) {
      baseFontSize = (canvasHeight * 0.3) / 2; // 30% of height
    } else {
      baseFontSize = (canvasWidth * 0.7) / 8; // 70% of width
    }

    // Apply preset's font size modifier to the responsive base size
    const fontSize = baseFontSize * preset.fontSize;

    return {
      ...preset,
      fontSize,
      scanSpeed: baseSize * preset.scanSpeed,
      aberrationOffset: baseSize * preset.aberrationOffset,
      scanLineDensity: Math.max(4, Math.floor(baseSize * preset.scanLineDensity)),
      lineHeight: Math.max(2, Math.floor(baseSize * preset.lineHeight))
    };
  }, []);

  const getAberrationColors = useCallback((textColor, useSpectral, aberrationIntensity) => {
    if (!useSpectral) {
      let r = 255, g = 255;
      if (textColor.startsWith('#')) {
        r = parseInt(textColor.slice(1, 3), 16);
        g = parseInt(textColor.slice(3, 5), 16);
      }
      return [
        `rgba(${r},0,0,${aberrationIntensity})`,
        `rgba(0,${g},0,${aberrationIntensity})`
      ];
    }
    return [
      `rgba(255,0,0,${aberrationIntensity})`,
      `rgba(255,165,0,${aberrationIntensity})`,
      `rgba(255,255,0,${aberrationIntensity})`,
      `rgba(0,255,0,${aberrationIntensity})`,
      `rgba(0,255,255,${aberrationIntensity})`,
      `rgba(0,0,255,${aberrationIntensity})`
    ];
  }, []);

  const updateCanvasSize = useCallback(() => {
    const dpr = window.devicePixelRatio || 1;
    const width = window.innerWidth;
    const height = window.innerHeight;

    [canvasRef, textCanvasRef, beamCanvasRef].forEach(ref => {
      if (!ref.current) return;
      const canvas = ref.current;
      canvas.width = width * dpr;
      canvas.height = height * dpr;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;

      const ctx = canvas.getContext('2d');
      ctx.scale(dpr, dpr);
    });
  }, []);

  // Then in drawTextToBuffer, use the fontSize directly:
  const drawTextToBuffer = useCallback(() => {
    if (!state.selectedPreset || !textCanvasRef.current) return;

    const textCanvas = textCanvasRef.current;
    const textCtx = textCanvas.getContext('2d');
    const canvas = canvasRef.current;
    const params = getScaledParams(canvas.width, canvas.height, state.selectedPreset);

    const fontFamily = state.loadedFonts.has(params.fontFamily)
      ? params.fontFamily
      : params.fallbackFont;

    textCtx.clearRect(0, 0, textCanvas.width, textCanvas.height);

    const lines = params.text.split('\n');
    const targetWidth = textCanvas.width * 0.8;

    const dpr = window.devicePixelRatio || 1;
    const canvasWidth = textCanvas.width / dpr;
    const canvasHeight = textCanvas.height / dpr;

    // Center coordinates
    const x = canvasWidth / 2;
    const y = canvasHeight / 2;

    let fontSize1 = params.fontSize;
    let fontSize2 = params.fontSize;

    textCtx.textAlign = 'center';
    textCtx.textBaseline = 'middle';

    let width1, width2;

    // Adjust font sizes to make text widths similar
    do {
      textCtx.font = `${params.fontWeight} ${fontSize1}px "${fontFamily}"`;
      width1 = textCtx.measureText(lines[0]).width;
      textCtx.font = `${params.fontWeight} ${fontSize2}px "${fontFamily}"`;
      width2 = textCtx.measureText(lines[1]).width;

      if (width1 > width2) fontSize1--;
      if (width2 > width1) fontSize2--;
    } while (Math.abs(width1 - width2) > 1 && fontSize1 > 0 && fontSize2 > 0);

    // Scale down if text is too wide
    if (width1 > targetWidth) {
      const scale = targetWidth / width1;
      fontSize1 *= scale;
      fontSize2 *= scale;
    }

    // Calculate vertical spacing
    const lineHeight = Math.max(fontSize1, fontSize2);
    const totalHeight = lineHeight * 1.2; // Add 20% spacing between lines
    const halfHeight = totalHeight / 2;

    // Calculate y positions for both lines
    const y1 = y - halfHeight / 2;  // First line position
    const y2 = y + halfHeight / 2;  // Second line position

    // Get aberration colors
    const aberrationColors = getAberrationColors(
      params.textColor,
      params.useSpectralAberration,
      params.aberrationIntensity
    );

    // Draw text with aberration
    if (params.useClassicAberration) {
      // Main text first
      textCtx.font = `${params.fontWeight} ${fontSize1}px "${fontFamily}"`;
      textCtx.fillStyle = params.textColor;
      textCtx.fillText(lines[0], x, y1);

      textCtx.font = `${params.fontWeight} ${fontSize2}px "${fontFamily}"`;
      textCtx.fillText(lines[1], x, y2);

      // Classic aberration on top
      aberrationColors.forEach((color, i) => {
        const maxOffset = params.aberrationOffset;
        const offset = params.useSpectralAberration
          ? maxOffset * (i / (aberrationColors.length - 1) - 0.5)
          : (i === 0 ? maxOffset : -maxOffset);

        const jitter = (Math.random() - 0.5) * params.aberrationOffset * 0.5;
        textCtx.fillStyle = color;

        textCtx.font = `${params.fontWeight} ${fontSize1}px "${fontFamily}"`;
        textCtx.fillText(lines[0], x + offset + jitter, y1);

        textCtx.font = `${params.fontWeight} ${fontSize2}px "${fontFamily}"`;
        textCtx.fillText(lines[1], x + offset + jitter, y2);
      });
    } else {
      // Modern style - aberration underneath
      aberrationColors.forEach((color, i) => {
        const maxOffset = params.aberrationOffset;
        const offset = params.useSpectralAberration
          ? maxOffset * (i / (aberrationColors.length - 1) - 0.5)
          : (i === 0 ? maxOffset : -maxOffset);

        const jitter = (Math.random() - 0.5) * params.aberrationOffset * 0.5;
        textCtx.fillStyle = color;

        textCtx.font = `${params.fontWeight} ${fontSize1}px "${fontFamily}"`;
        textCtx.fillText(lines[0], x + offset + jitter, y1);

        textCtx.font = `${params.fontWeight} ${fontSize2}px "${fontFamily}"`;
        textCtx.fillText(lines[1], x + offset + jitter, y2);
      });

      // Main text on top
      textCtx.font = `${params.fontWeight} ${fontSize1}px "${fontFamily}"`;
      textCtx.fillStyle = params.textColor;
      textCtx.fillText(lines[0], x, y1);

      textCtx.font = `${params.fontWeight} ${fontSize2}px "${fontFamily}"`;
      textCtx.fillText(lines[1], x, y2);
    }

    // Update text bounds
    textBoundsRef.current = {
      left: x - width1 / 2 - 10,
      right: x + width1 / 2 + 10,
      top: y - totalHeight / 2 - 10,
      bottom: y + totalHeight / 2 + 10
    };
  }, [state.selectedPreset, state.loadedFonts, getScaledParams, getAberrationColors]);

  const animate = useCallback((timestamp) => {
    if (!state.selectedPreset || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const beamCanvas = beamCanvasRef.current;
    const ctx = canvas.getContext('2d');
    const beamCtx = beamCanvas.getContext('2d');

    const params = getScaledParams(canvas.width, canvas.height, state.selectedPreset);
    const deltaTime = (timestamp - lastFrameTimeRef.current) / 1000;
    lastFrameTimeRef.current = timestamp;

    // Clear beam canvas with beam attenuation
    beamCtx.fillStyle = `rgba(0,0,0,${deltaTime / params.beamAttenuation})`;
    beamCtx.fillRect(0, 0, canvas.width, canvas.height);

    // Clear main canvas with text attenuation
    ctx.fillStyle = `rgba(0,0,0,${deltaTime / params.textAttenuation})`;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    if (animStateRef.current.scanLine < canvas.height) {
      const moveAmount = params.scanSpeed * deltaTime;
      animStateRef.current.beamX += moveAmount;

      if (animStateRef.current.beamX >= canvas.width) {
        animStateRef.current.beamX = 0;
        animStateRef.current.scanLine += params.lineHeight;
      }

      // Draw beam
      const gradient = beamCtx.createRadialGradient(
        animStateRef.current.beamX,
        animStateRef.current.scanLine,
        0,
        animStateRef.current.beamX,
        animStateRef.current.scanLine,
        10
      );
      gradient.addColorStop(0, `rgba(255,255,255,${params.beamIntensity})`);
      gradient.addColorStop(1, 'rgba(255,255,255,0)');
      beamCtx.fillStyle = gradient;
      beamCtx.beginPath();
      beamCtx.arc(animStateRef.current.beamX, animStateRef.current.scanLine, 10, 0, Math.PI * 2);
      beamCtx.fill();

      // Draw scan lines
      const scanLineOpacity = params.beamIntensity * 0.05;
      beamCtx.fillStyle = `rgba(255,255,255,${scanLineOpacity})`;
      for (let i = 0; i < canvas.height; i += params.scanLineDensity) {
        beamCtx.fillRect(0, i, canvas.width, 1);
      }
      for (let i = 0; i < canvas.width; i += params.scanLineDensity) {
        beamCtx.fillRect(i, 0, 1, canvas.height);
      }

      // Reveal text
      if (textCanvasRef.current) {
        const revealWidth = Math.ceil(moveAmount);
        const imageData = textCanvasRef.current.getContext('2d').getImageData(
          Math.floor(animStateRef.current.beamX),
          Math.floor(animStateRef.current.scanLine),
          revealWidth,
          params.lineHeight
        );
        ctx.putImageData(imageData, Math.floor(animStateRef.current.beamX), Math.floor(animStateRef.current.scanLine));
      }
    }

    if (animStateRef.current.scanLine >= canvas.height) {
      animStateRef.current.scanLine = 0;
      animStateRef.current.beamX = 0;
    }

    // Composite beam over the main canvas
    ctx.globalCompositeOperation = 'lighter';
    ctx.drawImage(beamCanvas, 0, 0);
    ctx.globalCompositeOperation = 'source-over';

    frameRef.current = requestAnimationFrame(animate);
  }, [state.selectedPreset, getScaledParams]);

  // Handle window resize
  const handleResize = useCallback(() => {
    if (resizeTimeoutRef.current) {
      clearTimeout(resizeTimeoutRef.current);
    }

    resizeTimeoutRef.current = setTimeout(() => {
      updateCanvasSize();
      drawTextToBuffer();
    }, 150);
  }, [updateCanvasSize, drawTextToBuffer]);

  // Initialize effect
  useEffect(() => {
  /**
   * Initializes the CRT beam effect by selecting a random preset and loading the required font.
   * If the font fails to load, it will use a fallback font and log a warning.
   * If there is an error during initialization, it will log the error and set the error state.
   */
    const initializeEffect = async () => {
      try {
        let preset;
        //preset = createRandomPreset(preset);
        //preset = getPresetByName('classic');
        if (!preset) {
          preset = selectRandomPreset();
        }
        console.log(`Selected preset: ${preset.name}`);
        console.log(`{
  ${preset.name}: {
    text: '${preset.text}',
    scanSpeed: ${preset.scanSpeed},
    beamIntensity: ${preset.beamIntensity},
    beamAttenuation: ${preset.beamAttenuation},
    textAttenuation: ${preset.textAttenuation},
    fontSize: ${preset.fontSize},
    aberrationOffset: ${preset.aberrationOffset},
    aberrationIntensity: ${preset.aberrationIntensity},
    scanLineDensity: ${preset.scanLineDensity},
    lineHeight: ${preset.lineHeight},
    textColor: '${preset.textColor}',
    useClassicAberration: ${preset.useClassicAberration},
    useSpectralAberration: ${preset.useSpectralAberration},
    fontFamily: '${preset.fontFamily}',
    fallbackFont: '${preset.fallbackFont}',
    fontWeight: '${preset.fontWeight}'
  }
}`);
        const results = await loadFonts([preset.fontFamily]);
        const loadedFont = results[0];

        if (loadedFont.loaded) {
          setState(prev => ({
            ...prev,
            fontsLoading: false,
            selectedPreset: preset,
            loadedFonts: new Set([preset.fontFamily])
          }));
        } else {
          console.warn(`Font ${preset.fontFamily} failed to load, using fallback`);
          setState(prev => ({
            ...prev,
            fontsLoading: false,
            selectedPreset: preset,
            loadedFonts: new Set()
          }));
        }
      } catch (err) {
        setState(prev => ({
          ...prev,
          fontsLoading: false,
          error: 'Failed to initialize effect'
        }));
        console.error('Initialization error:', err);
      }
    };

    initializeEffect();

    return () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, []);

  // Setup animation and event listeners
  useEffect(() => {
    if (!state.fontsLoading && state.selectedPreset) {
      window.addEventListener('resize', handleResize);
      updateCanvasSize();
      drawTextToBuffer();
      frameRef.current = requestAnimationFrame(animate);

      return () => {
        window.removeEventListener('resize', handleResize);
        if (frameRef.current) {
          cancelAnimationFrame(frameRef.current);
        }
      };
    }
  }, [state.fontsLoading, state.selectedPreset, handleResize, updateCanvasSize, drawTextToBuffer, animate]);

  // Error boundary UI
  if (state.error) {
    return (
      <div className="error-container">
        <div>
          <h2>Something went wrong</h2>
          <p>{state.error}</p>
          <button onClick={() => window.location.reload()}>
            Reload Page
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="crt-container">
      {state.fontsLoading ? (
        <div className="loading-screen">
          <div className="loading-content">
            <div className="spinner" />
            <p className="loading-text">Loading...</p>
          </div>
        </div>
      ) : (
        <>
          <canvas ref={canvasRef} className="main-canvas" />
          <canvas ref={textCanvasRef} className="hidden-canvas" />
          <canvas ref={beamCanvasRef} className="hidden-canvas" />
        </>
      )}
    </div>
  );
};

export default CRTBeamEffect;